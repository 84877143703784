import React from 'react'

const BeatportIcon = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110 69"
    >
      <g fill="currentColor">
        <path d="M94.46 40.19A39.8 39.8 0 0054.73.45 39.8 39.8 0 0015 40.2a39.6 39.6 0 0010.78 27.2 2.5 2.5 0 003.5.1l4.27-4.04a2.43 2.43 0 00.1-3.45 28.86 28.86 0 01-7.82-19.76 28.94 28.94 0 0128.9-28.9 28.94 28.94 0 0128.9 28.9c0 7.4-2.8 14.4-7.83 19.76-.93.99-.87 2.52.11 3.45l4.27 4.05a2.5 2.5 0 003.5-.11 39.7 39.7 0 0010.78-27.2"></path>
        <path d="M109.46 39.97c0-5.42-.82-10.73-2.4-15.87a2.5 2.5 0 00-3.07-1.59l-5.64 1.75a2.45 2.45 0 00-1.64 3.07 42.48 42.48 0 01-4.05 34.32 2.38 2.38 0 00.87 3.33L98.57 68c1.15.71 2.68.33 3.4-.82a52.58 52.58 0 007.5-27.2M16.8 61.64a42.33 42.33 0 01-4.05-34.31 2.45 2.45 0 00-1.64-3.07l-5.64-1.75a2.46 2.46 0 00-3.06 1.59A53.45 53.45 0 007.5 67.23a2.47 2.47 0 003.4.82l5.03-3.01a2.55 2.55 0 00.87-3.4"></path>
      </g>
    </svg>
  )
}

export default BeatportIcon
