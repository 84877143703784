import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Homepage from '../components/Homepage'

const SEOProps = {
  title: null, // Use default
  description: null, // Use default
}

const IndexPage = ({ location, data }) => (
  <Layout location={location} {...SEOProps}>
    <Homepage
      heroImg={data.heroImg.childImageSharp.fluid}
      bioImg={data.bioImg.childImageSharp.fluid}
      promoImg={data.promoImg.childImageSharp.fluid}
    />
  </Layout>
)

export const query = graphql`
  query HomepageQuery {
    heroImg: file(relativePath: { eq: "pizza.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bioImg: file(relativePath: { eq: "balloons.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    promoImg: file(relativePath: { eq: "tutoring-preview.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
