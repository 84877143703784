import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'

import Button from '../common/Button'
import BeatportIcon from '../svg/BeatportIcon'

import { TUTORING_URL } from '../../utils/urls'
import styles from './Homepage.module.css'

const Header = ({ className }) => <h1 className={cx(styles.title, className)}>LUXO</h1>

const Subheader = ({ className, children }) => (
  <h2 className={cx(styles.subtitle, className)}>{children}</h2>
)

const Homepage = ({ heroImg, promoImg, bioImg }) => {
  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <section className={styles.hero}>
          <Header className={styles.mainTitle} />

          <div className={styles.heroImgContainer}>
            <div className={styles.heroImgFilterContainer}>
              <Img
                fluid={heroImg}
                className={styles.heroImg}
                objectFit="cover"
                objectPosition="center center"
                alt="Luxo eating pizza"
              />
              <div className={styles.heroImgFilter} />
            </div>
          </div>

          <div className={styles.heroCtaButtons}>
            <Header className={styles.desktopHeader} />

            <div>
              <Button
                component="a"
                theme="primary"
                href="mailto:luxobeats@gmail.com"
                target="_blank"
                className={styles.headerBtn}
              >
                Contact
              </Button>
            </div>

            <div>
              <Button
                component={Link}
                theme="unstyled"
                className={styles.headerSubtitleBtn}
                to={TUTORING_URL}
              >
                Get Feedback On a Track
              </Button>
            </div>
          </div>
        </section>

        <div className={styles.border}>
          <span className={styles.pizza} role="img" aria-label="pizza">
            🍕
          </span>
          <span className={styles.pizza} role="img" aria-label="pizza">
            🍕
          </span>
          <span className={styles.pizza} role="img" aria-label="pizza">
            🍕
          </span>
        </div>

        <section className={styles.music}>
          <Subheader className={styles.musicTitle}>Music</Subheader>

          <div className={styles.musicBtns}>
            <Button
              className={cx(styles.musicBtn, styles.soundcloud)}
              component="a"
              href="https://soundcloud.com/luxo"
              target="_blank"
            >
              <FontAwesomeIcon icon={['fab', 'soundcloud']} className={styles.musicBtnIcon} />
              Soundcloud
            </Button>

            <Button
              className={cx(styles.musicBtn, styles.spotify)}
              component="a"
              href="https://open.spotify.com/artist/3ECUg6kTV5tp9vJ2UFNYZ9?si=1xzfuV2JSPuusnS74qCHaA"
              target="_blank"
            >
              <FontAwesomeIcon icon={['fab', 'spotify']} className={styles.musicBtnIcon} />
              Spotify
            </Button>

            <Button
              className={cx(styles.musicBtn, styles.beatport)}
              component="a"
              href="https://www.beatport.com/artist/luxo/284255"
              target="_blank"
            >
              <BeatportIcon className={styles.musicBtnIcon} />
              Beatport
            </Button>
          </div>
        </section>
      </header>

      <section className={styles.bio}>
        <Subheader>BIO</Subheader>

        <div className={styles.bioGrid}>
          <figure className={styles.bioImgContainer}>
            <Img
              fluid={bioImg}
              className={styles.bioImg}
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Luxo with balloons"
            />
          </figure>

          <div className={styles.bioTextContainer}>
            <p>
              Daniel “Luxo” Nish started producing dance music 6 years ago studying at Garnish Music
              School under the tutelage of beat scene producers PJ “Promnite” Sledge and Mike
              “Penthouse Penthouse” Parvizi.
            </p>
            <p>
              After he graduated, he continued to hone his craft until he revealed the Luxo project
              back in 2016. Since then, he has released on notable labels such as House of Hustle,
              Hood Politics Records and Late Night Munchies and has garnished over 100,000+ plays
              across music platforms.
            </p>
            <p>
              Not only has he found success in the music production world, but also as a DJ playing
              shows across the nation representing his record label Rock Bottom Records.
            </p>
          </div>
        </div>
      </section>

      <section className={styles.promotion}>
        <figure className={styles.promotionImgContainer}>
          <Img
            fluid={promoImg}
            className={styles.promotionImg}
            objectFit="cover"
            objectPosition="50% 50%"
            alt="Luxo standing in front of the decks"
          />
        </figure>

        <div className={styles.promoTextWrapper}>
          <div className={styles.promotionTextContainer}>
            <h3 className={styles.promotionTitle}>GET FEEDBACK AND MENTORING</h3>
            <p>Want to learn how to be a music producer from a touring professional?</p>
            <p>Want feedback on your next track?</p>
            <p>
              Book a live video session from the cofounder of up-and-coming House music label Rock
              Bottom Records.
            </p>

            <div className={styles.promotionCtaContainer}>
              <Button theme="primary" component={Link} to={TUTORING_URL}>
                Learn More
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Homepage
